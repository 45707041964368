import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import { isNil, dropRight } from 'lodash-es';
import { Upload, Input } from 'antd';
import { toast } from 'react-hot-toast';
import { Button, Icon } from 'components';
import { merchantFileType, merchantImageFilPurposeInverse, fileType } from 'enums';
import { setError, removeMedia, uploadFiles } from 'services';
import { ml, mr } from 'assets/css/globalCSS';
import {
  filesListStyles,
  fileListItem,
  filesContainer,
  toggleHiddenText,
  inputIcon,
  recommendationContainer,
} from './styles';

const UploadFiles = ({
  setMedia,
  mediaFiles,
  hasDescription,
  filePurpose,
  allowedFormat,
  recommendation,
  singleFile,
}) => {
  const theme = useTheme();
  const { getText } = useTranslations();
  const [fileInfo, setFileInfo] = useState({ file: null, description: null, isUploaded: null });
  const [fileList, setFileList] = useState(mediaFiles ?? []);
  const { Dragger } = Upload;

  const fileUploadConfig = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    accept: [allowedFormat].join(','),
    beforeUpload: file => fileValidation(file),
  };

  const fileValidation = file => {
    if (file.size > merchantFileType.sizeLimit) {
      toast.error(`${file.name} ${getText('uploadFailedFileSizeBigger')}`);
      return Upload.LIST_IGNORE;
    }

    if (!allowedFormat.includes(file.type)) {
      toast.error(`${file.name} ${getText('uploadFailedWrongFormat')}`);
      return Upload.LIST_IGNORE;
    }

    setFileInfo(prev => ({ ...prev, file, isUploaded: true }));
  };

  const updateData = (prop, value) => setFileInfo(prev => ({ ...prev, [prop]: value }));

  const handleUpload = async () => {
    const fileData = new FormData();
    fileData.append('description', fileInfo.description);
    fileData.append('file', fileInfo.file);
    fileData.append('purposeId', filePurpose);
    const [res, err] = await uploadFiles(fileData);
    err ? setError(err) : toast.success(getText('fileUploadedSuccessfully'));
    !isNil(res) && setFileList(prev => [...prev, res]);
    setFileInfo({ file: null, isUploaded: false, description: null });
    !isNil(res) &&
      setMedia(prev => ({
        ...prev,
        [merchantImageFilPurposeInverse[filePurpose]]: [...fileList, res].flatMap(({ id }) => ({ id })),
      }));
  };

  const removeItem = idx => {
    const { id } = fileList.at(idx);
    const newFileList = [...fileList];
    newFileList.splice(idx, 1);
    setFileList(newFileList);
    handleRemoveMedia(id);
  };

  const handleCancel = () => {
    if (!isNil(fileInfo.file)) setFileInfo({ file: null, isUploaded: false });
    if (fileList?.length && fileList.length > mediaFiles.length) {
      handleRemoveMedia(fileList[fileList.length - 1].id);
      const tempList = dropRight(fileList, 1);
      setFileList(tempList);
      updateData('isUploaded', false);
    }
  };

  const handleRemoveMedia = async id => {
    const [, err] = await removeMedia(id);
    err && setError(err);
  };

  return (
    <>
      <div className={filesContainer}>
        <Dragger {...fileUploadConfig} disabled={singleFile && fileList?.length}>
          <div className="upload-drag-icon">
            {fileInfo.isUploaded ? (
              <>
                <Icon iconName="las la-7x la-check-circle" className={`${inputIcon(theme)} success`} />
                <p className="upload-text">{getText('uploadSuccessful')}</p>
              </>
            ) : (
              <>
                <Icon iconName="las la-7x la-cloud-upload-alt" className={inputIcon(theme)} />
                <p className="upload-text">{getText('dragAndDropBrowseFiles')}</p>
                {recommendation && <p className={recommendationContainer(theme)}>{getText(recommendation)}</p>}
              </>
            )}
          </div>
        </Dragger>
        <ul className={filesListStyles}>
          {fileList?.map((file, idx) => (
            <li key={idx} className={fileListItem(theme)}>
              {file?.typeId !== fileType.pdf && (
                <img src={file.thumbnailUrl} alt="preview-img" className="thumbnail-img" />
              )}
              <div className="file-info">
                <p className={`file-name ${toggleHiddenText}`}>{file?.name}</p>
                {file?.description !== 'null' && file?.description && (
                  <p className={`file-description ${toggleHiddenText}`}>{file?.description}</p>
                )}
              </div>
              <Button small type="default" onClick={() => removeItem(idx)}>
                <Icon iconName="las la-lg la-trash-alt" />
              </Button>
            </li>
          ))}
        </ul>
      </div>
      <div className="flex upload-field-type">
        {hasDescription && (
          <Input
            type="text"
            placeholder={getText('enterFileName')}
            onChange={e => updateData('description', e.target.value)}
            disabled={isNil(fileInfo?.file)}
            value={fileInfo?.description}
            className={mr({ lg: 10 })}
          />
        )}
        <Button type="default" small onClick={handleCancel} disabled={isNil(fileInfo?.file)}>
          {getText('cancel')}
        </Button>
        <Button
          className={ml({ lg: 10 })}
          type="primary"
          small
          onClick={handleUpload}
          disabled={hasDescription ? isNil(fileInfo?.description) : isNil(fileInfo?.file)}
        >
          {getText('submit')}
        </Button>
      </div>
    </>
  );
};

UploadFiles.propTypes = {
  setMedia: PropTypes.func,
  mediaFiles: PropTypes.array,
  hasDescription: PropTypes.bool,
  filePurpose: PropTypes.number,
  allowedFormat: PropTypes.array,
  recommendation: PropTypes.string,
  label: PropTypes.string,
  labelInformation: PropTypes.string,
  singleFile: PropTypes.bool,
};

export default UploadFiles;
