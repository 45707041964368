import React, { useRef, useState } from 'react';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import { pick } from 'lodash';
import { isNil } from '@veraio/core';
import { isMobile } from 'react-device-detect';
import { useLocations } from '@oneecosystem/dealshaker-core';
import {
  useUrlParams,
  Pagination,
  Button,
  Modal,
  CountryFilter,
  CityFilter,
  MerchantListCategoryFilter,
  Icon,
  useDeepEffect,
  SearchInput,
} from 'components';
import { useMerchantList, fetchMerchants, findCategoryPath, setBreadcrumbs, setCategories, setLocation } from 'stores';
import { setError, getAllCategories } from 'services';
import noResults from 'assets/images/no-results-person.svg';
import { MerchantListCard } from './Merchant';
import {
  merchantListContainer,
  merchantListFilters,
  merchantListCardContainer,
  filterContainer,
  merchantListHeader,
  filterButtonMobile,
  filterButtonsMobileContainer,
  clear,
  filterModal,
  selectFilterButton,
  filterHeader,
  searchBar,
} from './styles';

const filterKeys = {
  name: 'name',
  location: 'location',
  categoryId: 'categoryId',
  cities: 'cities',
  countryId: 'countryId',
  cityName: 'cityName',
};

export const MerchantList = () => {
  const theme = useTheme();
  const paginationRef = useRef();
  const modalRef = useRef();
  const { getText } = useTranslations();
  const [locationModal, setLocationModal] = useState(false);
  const [categoryModal, setCategoryModal] = useState(false);
  const { countries } = useLocations();
  const { queryParams, setQueryParams } = useUrlParams();
  const [totalMerchants, setTotalMerchants] = useState(null);
  const [allCategories, setAllCategories] = useState(null);
  const location = countries?.find(country => country.id === queryParams?.countryId)?.name;
  const { merchantList, breadcrumbs, locationFilter } = useMerchantList(store =>
    pick(store, ['merchantList', 'breadcrumbs', 'locationFilter']),
  );

  useDeepEffect(() => {
    fetchAllCategories();
    handlePreselectLocation();
  }, [location]);

  const fetchAllCategories = async () => {
    const [res, err] = await getAllCategories();
    if (err) return setError(err);
    queryParams?.categoryId && setBreadcrumbs(findCategoryPath(res, queryParams?.categoryId));

    setAllCategories(res);
    return res;
  };

  const fetchMerchantsData = async options => {
    const [res, err] = await fetchMerchants({ ...queryParams, ...options });
    if (err) return setError(err);
    setTotalMerchants(res?.totalCount);
    return res;
  };

  const handlePreselectLocation = () =>
    setLocation({
      country: countries?.find(country => country.id === queryParams.countryId),
      cities: queryParams?.cities,
    });

  const handleDeselectFilter = () => {
    setCategories({ categoryId: null });
    setBreadcrumbs([]);
    setQueryParams({ ...queryParams, categoryId: null });
  };

  const handleDeselectLocationFilter = () => {
    setLocation({ country: null, cities: null });
    setQueryParams({ ...queryParams, countryId: null, cities: null });
  };

  const handleOnBackCategories = () => {
    if (!breadcrumbs) return;

    setBreadcrumbs(breadcrumbs.slice(0, -1));
    setCategories(breadcrumbs[breadcrumbs?.length - 2]?.subCategories);
  };

  const handleSearchChange = value => {
    setQueryParams({ ...queryParams, name: value });
    paginationRef.current?.applyFilters({ name: value });
  };

  const handleOnBackLocation = () => setLocation({ cities: null, country: null });

  return (
    <div className="content-container">
      <div className={merchantListHeader}>
        <h1 className="title">{getText('findMerchant')}</h1>
        <SearchInput
          allowClear
          className={searchBar(theme)}
          placeholder={getText('searchByMerchantName')}
          defaultValue={queryParams[filterKeys.name]}
          onPressEnter={handleSearchChange}
        />
      </div>
      <div className={merchantListContainer}>
        <div className={merchantListFilters}>
          {isMobile ? (
            <div className={filterButtonsMobileContainer}>
              <div className="cta-buttons">
                <Button
                  small
                  type="default"
                  onClick={() => setCategoryModal(true)}
                  className={filterButtonMobile(queryParams.categoryId, theme)}
                >
                  {getText('categories')}
                </Button>
                <Button
                  small
                  type="default"
                  onClick={() => setLocationModal(true)}
                  className={filterButtonMobile(queryParams.countryId, theme)}
                >
                  {getText('location')}
                </Button>
              </div>
              <Button small fullWidth type="info" onClick={handleDeselectFilter} className={clear}>
                {getText('clear')}
              </Button>
            </div>
          ) : (
            <>
              <h4 className={filterHeader(theme)}>Filters</h4>
              <div className={filterContainer(theme)}>
                <h4 className="filter-title">Categories</h4>
                {queryParams?.categoryId && breadcrumbs?.length ? (
                  <div className="selected">
                    <div className="currentSelection">
                      <p className="label">{getText('currentSelection')}</p>
                      <div>
                        {breadcrumbs.map(path => (
                          <span>
                            {!path?.subCategories?.length ? <b>{getText(path.name)}</b> : getText(path.name)}
                            {!!path?.subCategories?.length && ' > '}
                          </span>
                        ))}
                      </div>
                    </div>
                    <Button small type="link" onClick={handleDeselectFilter}>
                      {getText('clear')}
                    </Button>
                  </div>
                ) : (
                  <div className="not-selected">
                    <p>{getText('findMerchantByCategories')}</p>
                    <Button type="link" onClick={() => setCategoryModal(true)} className={selectFilterButton}>
                      {getText('select')}
                    </Button>
                  </div>
                )}
              </div>
              <div className={filterContainer(theme)}>
                <h4 className="filter-title">Merchant`s location</h4>
                {queryParams?.countryId && (!isNil(locationFilter?.cities) || !isNil(locationFilter?.country)) ? (
                  <div className="selected">
                    <div className="currentSelection">
                      <p className="label">{getText('currentSelection')}</p>
                      <div>
                        {locationFilter?.cities ? `${locationFilter?.cities?.join(', ')}, ` : ''}{' '}
                        {locationFilter?.country?.name}
                      </div>
                    </div>
                    <Button small type="link" onClick={handleDeselectLocationFilter}>
                      {getText('clear')}
                    </Button>
                  </div>
                ) : (
                  <div className="not-selected">
                    <p>{getText('searchForCountryOrCity')}</p>
                    <Button type="link" onClick={() => setLocationModal(true)} className={selectFilterButton}>
                      {getText('select')}
                    </Button>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        <div className={merchantListCardContainer(theme)}>
          <h6 className="merchant-found">{getText('merchantFoundCount', { count: totalMerchants })}</h6>
          {merchantList?.length ? (
            merchantList.map(merchant => <MerchantListCard key={merchant.id} merchant={merchant} />)
          ) : (
            <div className="no-merchant-found">
              <img src={noResults} alt="No results" />
              <p>{getText('noMerchantsFound')}</p>
            </div>
          )}
          <Pagination
            ref={paginationRef}
            onChange={fetchMerchantsData}
            pageSize={10}
            filterKeys={filterKeys}
            justify="center"
          />
        </div>
      </div>
      {/* categories  filter modal */}
      <Modal
        className={filterModal}
        show={categoryModal}
        positioned={isMobile ? 'bottom' : 'center'}
        ref={modalRef}
        onClose={() => setCategoryModal(false)}
        {...(breadcrumbs?.length && { onBack: handleOnBackCategories })}
        modalTitle={
          breadcrumbs?.length ? getText(breadcrumbs[breadcrumbs.length - 1]?.name) : getText('pleaseSelectCategory')
        }
        closeIcon={<Icon iconName="las la-times" onClick={() => setCategoryModal(false)} />}
      >
        <MerchantListCategoryFilter
          onChange={paginationRef.current?.applyFilters}
          filterKeys={filterKeys}
          closeModal={() => setCategoryModal(false)}
          allCategories={allCategories}
        />
      </Modal>

      {/* country and city filter modal */}
      <Modal
        className={filterModal}
        show={locationModal}
        positioned={isMobile ? 'bottom' : 'center'}
        ref={modalRef}
        onClose={() => setLocationModal(false)}
        {...(locationFilter?.country && { onBack: handleOnBackLocation })}
        modalTitle={
          locationFilter?.country?.name ? getText(locationFilter?.country?.name) : getText('pleaseSelectCountry')
        }
        closeIcon={<Icon iconName="las la-times" onClick={() => setLocationModal(false)} />}
      >
        {!locationFilter?.country && <CountryFilter />}
        {locationFilter?.country && (
          <CityFilter
            multiSelect
            onClose={() => setLocationModal(false)}
            onChange={paginationRef.current?.applyFilters}
            filterKeys={filterKeys}
          />
        )}
      </Modal>
    </div>
  );
};

MerchantList.propTypes = {};
