import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getText } from '@veraio/strank';
import { pick, isNil } from 'lodash';
import { useTheme } from 'emotion-theming';
import { SearchBar, Skeleton, useUrlParams, Button, Tag } from 'components';
import { useMerchantList, setLocation } from 'stores';
import noResults from 'assets/images/no-results-person.svg';
import {
  cityFilterContainer,
  cityFilterSearchBar,
  cityFilterItem,
  cityFilterBody,
  cityFilterCTAButtons,
} from './styles';

export const CityFilter = ({ onClose, filterKeys, onChange, multiSelect }) => {
  const theme = useTheme();
  const { queryParams, setQueryParams } = useUrlParams();
  const [selectedCities, setSelectedCities] = useState([]);
  const { citiesList, locationFilter } = useMerchantList(store => pick(store, ['citiesList', 'locationFilter']));

  const handleSelectCity = (city, e) => {
    if (multiSelect) {
      const isSelected = selectedCities?.findIndex(c => c.id === city.id) === -1;

      isSelected
        ? setSelectedCities(prev => [...prev, city])
        : setSelectedCities(prev => prev.filter(el => el.id !== city.id));

      if (city?.id === 0) {
        if (isChecked(e.currentTarget, isSelected)) setSelectedCities([{ id: 0 }, ...citiesList]);
        else setSelectedCities([]);
      }

      isChecked(e.currentTarget, isSelected)
        ? e.target.classList.add('selected')
        : e.target.classList.remove('selected');
    }

    if (!multiSelect) {
      const cities = city?.id === 0 ? null : city.name;
      handleSetCities(cities);
    }
  };

  const isChecked = (target, isSelected) => {
    const input = target?.querySelector('input');
    if (!input) return;
    input.checked = isSelected;
    return isSelected;
  };

  const handleSetCities = cities => {
    setLocation({ cities, country: locationFilter.country });
    setQueryParams({ ...queryParams, cities });
    onChange({
      [filterKeys.cities]: cities,
      [filterKeys.countryId]: locationFilter.country.id,
    });
    onClose();
  };

  const handleApply = () => {
    const cities = selectedCities.map(city => city.name);
    handleSetCities(cities);
  };

  const handleClear = () => {
    setSelectedCities([]);
  };

  return (
    <div className={cityFilterContainer}>
      <SearchBar
        placeholder={getText('searchForCity')}
        displayKey="name"
        options={citiesList}
        className={cityFilterSearchBar}
        renderSuggestion={val => (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            {...val.itemProps}
            className={`${val.itemProps.className} suggestion`}
            role={val.itemProps.role}
            onClick={e => {
              handleSelectCity(val.item, e);
              val.suggestionItemProps.onClick(val.item);
            }}
          >
            {val.item.name}
          </div>
        )}
      />

      <div className={cityFilterBody}>
        <div className="tags">
          {selectedCities?.length === citiesList?.length + 1 ? (
            <Tag type="grayLight" className="city-tag">
              {getText('all')}
            </Tag>
          ) : (
            selectedCities?.map(
              city =>
                city.id !== 0 && (
                  <Tag type="grayLight" className="city-tag">
                    {city.name}
                  </Tag>
                ),
            )
          )}
        </div>
        {!isNil(citiesList) ? (
          citiesList?.length ? (
            [{ id: 0, name: `All cities in ${locationFilter?.country?.name}` }, ...citiesList].map((city, i) => (
              <div
                key={i}
                role="button"
                tabIndex={0}
                onClick={e => handleSelectCity(city, e)}
                className={cityFilterItem(theme)}
              >
                {multiSelect && <input type="checkbox" checked={selectedCities.some(el => el.id === city.id)} />}
                <div className="city-name">{city.name}</div>
              </div>
            ))
          ) : (
            <div className="no-merchant-found">
              <img src={noResults} alt="No results" />
              <p>{getText('noMerchantsFound')}</p>
            </div>
          )
        ) : (
          [...Array(5)].map((_, index) => <Skeleton key={index} marginBottom="0.6em" height="4rem" />)
        )}
      </div>
      {!isNil(citiesList) && !!citiesList?.length && (
        <div className={cityFilterCTAButtons}>
          <Button small type="secondary" onClick={handleApply} disabled={!selectedCities?.length}>
            {getText('apply')}
          </Button>
          <Button small type="info" onClick={handleClear}>
            {getText('clear')}
          </Button>
        </div>
      )}
    </div>
  );
};

CityFilter.propTypes = {
  options: PropTypes.array,
  onClose: PropTypes.func,
  filterKeys: PropTypes.object,
  onChange: PropTypes.func,
  multiSelect: PropTypes.bool,
};
